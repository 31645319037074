.lobbyTopContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.lobbyTopItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 6rem;
	padding: 0 20px;
	color: white;
}

.lobbyTopItem .iconMember {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: rgb(62, 62, 62);
    border: 2px solid rgb(62, 62, 62);
	border-radius: 50%;
	margin-bottom: 3px;
}

.lobbyTopItem .iconPlayer {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: rgb(246, 74, 0);
	border-radius: 50%;
	margin-bottom: 3px;
}

.lobbyTopDivider {
	width: 1px;
	height: 30px;
	border: 0.5px solid rgb(88, 88, 88);
}

.hr-line {
	border: 0.5px solid rgb(88, 88, 88);
	opacity: 1 !important;
	margin: 0.5rem 0 !important;
}

.hr-line-mobile {
	border: 0.5px solid rgb(88, 88, 88);
	opacity: 1 !important;
	margin: 0.5rem 30px !important;
}

.offcanvas-body {
	background-color: rgb(55, 55, 55) !important;
}

.sidebar {
	background: rgb(55, 55, 55) !important;
	/* border-right: 1px solid rgb(74, 74, 74); */
}

.nav-item {
	position: relative;
}

/* .nav-item.active .nav-link,
.nav-item.active .logout-nav,
.text-black {
	color: black;
} */

.provider-link.active {
	background-color: #e0e0e0;
}

/* .subnav {
	background-color: white !important;
	border-top: 1px solid rgb(211, 211, 211);
	margin-bottom: -1px;
} */

.nft .slick-dots {
	bottom: 5px !important;
}

.slick-slide img {
	aspect-ratio: 0 !important;
	height: 240px !important;
	width: 100% !important;
	object-fit: contain !important;
}

.slick-slide-text {
	font-size: 16px;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.slick-slide img {
		aspect-ratio: 0 !important;
		height: 100% !important;
		width: 100% !important;
	}

	.slick-slide-text {
		font-size: 12px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}