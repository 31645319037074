@import "_theme-variables.scss";

/*general*/
.badge {
    padding: 5px 10px;
    font-weight: 600;

    &.main {
        background-color: $light_yellow !important;
        color: $black;
    }

    &.bg-ongoing {
        color: $yellow;
        background-color: $light_yellow;

    }

    &.bg-ending {
        color: #f05a24;
        background-color: #ffdfd7;
    }
}

.row {
    margin-right: calc(-.3 * var(--bs-gutter-x));
    margin-left: calc(-.3 * var(--bs-gutter-x));
}

.row>* {
    padding-right: calc(var(--bs-gutter-x) * .3);
    padding-left: calc(var(--bs-gutter-x) * .3);
}

.bg-main {
    background-color: $light_yellow;
}

.bg-multiple {
    background: $multiple-gradient;
}

.bg-gold-gradient {
    background: $gold-gradient;
    border: none;
}

.btn {
    width: 100%;
    padding: 8px 18px;
    font-weight: 600;

    &.outline,
    &.outline:active {
        border: 1px solid $dark_purple;
    }

    &.no-bg {
        background: transparent;
        border: none
    }
}

.btn-secondary {
    &:disabled {
        opacity: 0.35
    }
}

.btn-sm {
    padding: 6px 25px;
}

.text-gradient {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    background: linear-gradient(to bottom, $red, $color_more);
}

.de-flex {
    display: flex;
    justify-content: space-between;
}

.de-flex>.de-flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-main {
    display: block;
    width: max-content;
    text-align: center;
    color: $black_more;
    background: $green;
    border-radius: 5px;
    letter-spacing: normal;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 15px 0px rgba(42, 124, 110, 0);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 10px 0px $green;
        transition: all 0.3s ease;
    }

    &.inline {
        display: inline-block;
        margin-right: 15px;

        &.white {
            color: $color_more !important;
            background: $white;
        }
    }
}

.m-a-0 {
    margin: auto 0;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.copy-position {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
}

.col-space {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

// .col {
//   flex-direction: column;
//   display: flex;
//   justify-content: space-between;
// }

#orcGraph p,
#orcGraph h5 {
    margin: 0;
}

.div-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.border-left {
    border-left: 1px solid;
}

.text-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 75px;

    p {
        margin: 0
    }
}

.box {
    padding: 12px;
    border: 1px solid;
    border-radius: 15px;

    &:hover {
        background: #212447;
    }
}

.box-br30 {
    padding: 5px 20px;
    border: 1px solid;
    border-radius: 30px;
}

#earnDate p {
    padding: 2px 10px;
    border-radius: 15px;
    font-size: 14px;
    border: 1px solid;
    margin: 0;
    // color: $white;
}

.active-date {
    background: $yellow_more;
    border: 1px solid $yellow_more !important;
    color: $black;
}

.max-fit {
    max-width: fit-content;
}

#stake h4 {
    font-weight: normal;
    margin: 0;
}

#staking p {
    margin: 0;
    align-self: end;
}

//modal
.modal-header {
    border: none;
    padding: 0;
    background-color: rgb(255, 78, 0);
    margin-bottom: 15px;
    border-radius: 18px 18px 0 0;
    display: inline-grid;
}

.modal-header .row{
    width: 100%;
    text-align-last: center;
}

.modal-header p{
    color: black;
    font-size: 18px;
    margin-bottom: 10px;
}

.modal {
    --bs-modal-border-radius: 20px;
}

.modal-body {
    padding-top: 0;
    padding-bottom: 40px;
}

.modal-body-title {
    border-bottom: 1px solid #bcbcbc;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 25px;
    color: white
}

#select {
    button {
        background-color: $light_yellow;
        border: none;
        color: $black_more;
        text-align: left;
    }

    .dropdown-menu {
        width: 100%;
        margin: 0;
    }

    .dropdown-item {
        margin-bottom: 4px;
    }

    img {
        width: 10%;
        margin-right: 10px;
    }
}

//form
.form-control {
    font-size: 14px;
    border: none;
    padding: 10px 12px;
    margin-bottom: 1rem;
    background: #d4d4d4;
    text-align: center;

    &:focus,
    &:disabled {
        box-shadow: none;
        background: #ffffff;
    }

    &:disabled {
        cursor: not-allowed;
        background: #ffffff;
    }
}

.form-select,
.form-select:focus {
    border: none;
    padding: 15px 12px;
    font-size: 14px;
    box-shadow: none;
    border-bottom: 1px solid;
    border-radius: 0;
}

.form-sub {
    top: 8px;
    right: 15px;
    // color: $black;
}

.form-text {
    color: $yellow_more;
}

.text-yellow {
    color: $yellow
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.rangeslider-horizontal {
    height: 10px;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
}

.rangeslider-horizontal .rangeslider__handle:after {
    content: none;
}

.nft-height {
    height: 440px;
    overflow-x: auto;
}

#dashboardLink {
    a {
        color: $white;
        text-decoration: none;

        .blue-bg-gradient:hover {
            background: rgba(47, 70, 152, 0.7);
        }
    }

    h3,
    h5,
    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#walletConnect {
    justify-content: space-between;

    &:hover {
        font-weight: bold;
        background: $green-soft;
        transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
        color: black;
    }
}

.convertRate {
    color: $white;
    font-size: 14px;
}

#scroll-to-top div {
    background: $green;
}

.hierarchy-bg {
    border-radius: 15px;
    padding: 5px 10px;
    border: 1px solid $gray;
    font-size: 14px;
}

.ml-20 {
    margin-left: 20px;
    color: $yellow_more;
}

.cursor-default {
    cursor: default;
}

.iJhIuy {
    font-size: 20px !important;
}

.disconnect-dd {
    left: 15px;
    transform: none;
}

#h-value {
    color: $white;
    text-decoration: none;

    &:hover {
        color: $green;
    }
}

#hierarchy {
    .fa-fw {
        font-size: 8px;
        margin: 0 3px;
    }
}

// iframe {
//   pointer-events: none;
// }

.font-normal {
    font-weight: normal;
}

.jumbo-bg {
    overflow: hidden;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}

.text-left {
    text-align: left;
}

.justify-content-initial {
    justify-content: initial;
}

#presales-box {
    color: $white;

    p {
        margin-bottom: 0.5rem;
    }

    h4 {
        color: $yellow_more;
    }
}

.vr {
    min-height: 100%;
    background-color: $white;
    opacity: 0.5;
}

.wallet-m3 {
    margin: 0 40px;
}

#tablet-main-logo {
    display: none;
}

.presales-main1 {
    font-family: goodTimes;
    font-weight: normal;
    line-height: 80px;
}

.presales-main2 {
    font-family: zArista;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 5px;
    text-shadow: 1px 1px 5px #fad93b, 1px 1px 5px #ccc;

    .glow {
        color: #fad93b;
    }
}

.text-glow {
    text-shadow: 0px 0px 5px #fad93b, 0px 0px 5px #ccc;
}

.word-break {
    word-break: break-all;
}

#timer {
    padding: 10px 20px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;

    h4 {
        margin-bottom: 0;
        color: #daf6ff;
        text-shadow: 0 0 20px #0aafe6, 0 0 20px rgb(10 175 230 / 0%);

        span {
            font-size: 18px;
        }
    }
}

.participation-text {
    margin: 0;
    line-height: 2rem;
}

#sales-text {
    span {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        color: $yellow_more;
    }
}

.banner-height {
    // height:250px;
    border-radius: 25px;
}

.margin-left {
    margin-left: 0.5rem !important;
}

.t-logo {
    margin-bottom: 6px;
}

.t-logo2 {
    margin-bottom: 8px;
}

@media only screen and (max-width: 376px) {
    .icon-width {
        font-size: 20px !important;
        width: 22.7% !important;
    }
}

.icon-width {
    font-size: 20px;
    width: 20.7%;
}

.certik-mp {
    margin-top: 15% !important;
}

.w-certik-80 {
    width: 80% !important;
}

.fa-lg {
    font-size: 25px;
}

/*** card design ***/
.widget-visitor-card {
    overflow: hidden;
    padding: 10px 0;

    // border: none;
    i {
        color: $white;
        font-size: 80px;
        position: absolute;
        bottom: 30px;
        opacity: 0.3;
        right: -10px;
        transform: rotate(-15deg);
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        i {
            transform: rotate(0deg) scale(1.4);
            opacity: 0.5;
        }
    }
}

.social-widget-card {
    &:hover i {
        opacity: 1;
        transform: scale(1.1);
    }

    i {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 48px;
        opacity: 0.4;
        transition: all ease-in 0.3s;
    }
}

.progress-bar-striped {
    background-color: $red;
}

.fa-circle {
    font-size: 10px;
}

.dropdownIni {
    display: none;
}

.show {
    display: block;
}

.horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 2rem;
        font-weight: bold;
    }
}

.hyperlink-removed {
    color: black !important;
    cursor: pointer !important;
    text-decoration: none !important;
}

.order-card {
    color: $white;
    overflow: hidden;

    // border: none;
    .card-icon {
        position: absolute;
        right: -17px;
        font-size: 100px;
        top: 20px;
        opacity: 0.5;
    }
}

.border-bg {
    text-align: center;
    padding: 16px;
    border-radius: 999px;
    border: 1px solid #cccccc;
}

.border-page-title {
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;
    text-transform: uppercase;
}

.border-page-title:before {
    right: 1.5em;
    margin-left: -50%;
}

.border-page-title:after {
    left: 1.5em;
    margin-right: -50%;
}

.border-page-title:after,
.border-page-title:before {
    background-color: $green;
    content: '';
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.subnav {
    background: #f5f5f5;

    svg {
        font-size: 16px;
    }

    a,
    .logout-nav {
        align-items: center;
        padding: 10px 0 6px;
        text-align: center;
        width: 100px;
        color: #94909D;
        font-weight: bold;

        &:hover,
        &:focus {
            color: white;
            text-decoration: none;
        }

        img {
            width: 20px;
            filter: brightness(0) saturate(100%) invert(66%) sepia(2%) saturate(1763%) hue-rotate(217deg) brightness(90%) contrast(76%);
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    p {
        font-size: 12px;
        margin: 0;
    }

    .nav-link.active {
        color: rgb(255, 78, 0) !important;

        img {
            filter: brightness(0) saturate(100%) invert(41%) sepia(52%) saturate(6960%) hue-rotate(6deg) brightness(107%) contrast(103%);
        }
    }
}

.pd-card {
    padding: 30px 0;
}

.chartContainer {
    height: 400px;
}

.text-token {
    color: #bbb;
}

#token tr {
    &:hover {
        background: #1f325c;
    }
}

.chartview {
    height: 100%;
    width: 100%;
}

.chart-container {
    flex: 1;
}

.br-15 {
    border-radius: 15px;
}

.mobileLogo {
    display: none;
}

.headerRow {
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;

    a {
        text-decoration: none;
    }
}

.search-btn {
    border-radius: 0 !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    margin: 0;
}

.address-bd {
    padding: 8px 15px;
    border-radius: 999px;
    font-size: 14px;
    background: $white;
    color: $yellow_more;
}

#withdraw-earn {
    .card-body {
        padding: 0;

        .rowData {
            padding: 14px 23px;
            border-bottom: 1px solid $blue;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }

            p {
                margin: 0;
            }

            &:nth-child(2) {
                background-color: $blue;
            }
        }
    }
}

#withdraw-bal {
    .card {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

#withdrawal {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 0;
    }

    .withdrawal-amt {
        border-radius: 999px;
        color: white;
        padding: 5px 15px;
    }
}

.scrollable-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

#announcement {
    h4 {
        font-weight: 800;
    }

    .date {
        color: #979797;
        margin: 0;
    }

    .content {
        height: 60px;
        line-height: 1.5;
        word-break: break-word;
        margin: 12px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

#trading {
    height: 380px;
    overflow: hidden;

    p {
        margin-bottom: 8px;
    }

    .autoscroll {
        width: 100%;
        height: auto;
        top: 0;
        // animation: scroll 50s linear infinite; /* Adjust the animation duration as needed */

        @keyframes scroll {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(-100%);
            }
        }

        &:hover {
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
        }
    }
}

.market {
    background: '#ebf0f6';
    border-radius: 23px;
}

.qr-bg {
    position: absolute;
    width: 45%;
    // top: 59.5%;
    // left: 50%;
    // transform: translate(-50%, -50%);
}

.mt-35 {
    margin-top: 35px !important;
}

.p-25 {
    padding: 25px;
}

#image-icon {
    border-radius: 999px;
    background-color: #f8f8f8;
}

.border-main {
    border: 2px solid $blue;
}

.contract-desc {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
}

.contract-title {
    width: fit-content;
    border-bottom: 1px solid $card-outline;
    margin: auto auto 12px;
    line-height: 25px;
}

.col-space {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: $yellow
}

.buttonload {
    border: none;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
}

.fa-spin {
    margin-right: 8px;
}


@keyframes animloader {
    0% {
        box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
    }

    33% {
        box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
    }

    66% {
        box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
    }

    100% {
        box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
    }
}

.slick-list {
    border-radius: 15px;
}

.btn-close:focus {
    box-shadow: none;
}

.slick-slide img {
    width: 100%;
    margin: auto;
    aspect-ratio: 16/9;
    height: 150px;
    object-position: center;
    object-fit: cover;
}

.react-toggle-track-check,
.react-toggle-track-x {
    height: 14px;
}

.react-toggle--checked .react-toggle-track {
    background-color: transparent;
}

.cta-btn {
    padding: 10px 25px;
    text-decoration: none;
    color: white;
    background: $purple;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
}

.cta-outline-btn {
    @extend .cta-btn;
    border-radius: 0;
    border: 2px solid $purple;

    &:hover {
        background: none;

    }
}

.input-with-icon {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: grey;
}

.home_marquee {
    background: rgb(62, 62, 62);
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    color: white;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 14px;
    margin-top: -2px;
    position: fixed;
    z-index: 2;
    top: 44px;
    width: 100%;
    top: 45px;

    .announcement--text {

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media (min-width: 968px) {
        top: 50px;
    }
}

.announcement_wrapper {
    background-color: $dark_purple;
    padding: 80px 0;
}

.announcement--body {
    min-height: 100vh;

    .row {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;

        }
    }

    .card {
        background-color: $yellow;
        color: $black;
    }
}

.announcement--title {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.main-height {
    // margin-top: 1.2rem;
}

.game_image {
    object-fit: cover;
    border-radius: 15px;
    min-height: 318px;

    @media (max-width: 576px) {
        min-height: 200px;
    }
}

.game_image_square {
    object-fit: cover;
    border-radius: 15px;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
}

.tab_wrapper {
    overflow: hidden;
    overflow-y: auto;
    position: relative;

    .tab {
        cursor: context-menu;
        height: 4vw;
        width: 4vw;
        min-height: 60px;
        max-height: 70px;
        min-width: 60px;
        max-width: 70px;
        white-space: normal;
        display: block;
        line-height: 12px;
        margin-bottom: 15px;
        text-decoration: none;
        text-align: center;
        border: none;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        flex-direction: column;
        row-gap: 6px;
        background-color: rgb(62, 62, 62);
        border: 2px solid rgb(62, 62, 62);
        color: rgb(165, 165, 165);

        img {
            filter: brightness(0) saturate(100%) invert(76%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(87%) contrast(91%);
        }

        &:hover {
            border: 2px solid rgba(255, 78, 0, 0.7);
            background-color:rgba(255, 78, 0, 0.1);
            // color: white;
            cursor: pointer;
            
            // img {
            //     filter: brightness(0) saturate(100%) invert(25%) sepia(96%) saturate(611%) hue-rotate(358deg) brightness(97%) contrast(103%);
            // }
        }

        &.active {
            background-color: black;
            color: rgb(255, 78,0);
            border: 2px solid rgb(255, 0, 0);

            img {
                filter: brightness(0) saturate(100%) invert(41%) sepia(52%) saturate(6960%) hue-rotate(6deg) brightness(107%) contrast(103%);
            }
        }
    }
}

.welcome-text {
    background: #def5ff;
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
    color: #1b5587;
    font-weight: bold;
    letter-spacing: 0;
}

.dashboard-wrapper {
    padding-right: 3rem;
    padding-left: 3rem;
    padding-top: 85px;

    @media (max-width: 576px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    @media (min-width: 968px) {
        padding-top: 95px;
    }
}

.dashboard-provider-wrapper {
    --bs-gutter-x: 1.5rem;

    @media (max-width: 576px) {
        --bs-gutter-x: 1rem;
    }
}

.provider-wrapper {
    --bs-gutter-y: 1rem;

    @media (max-width: 576px) {
        --bs-gutter-x: 0.5rem;
        --bs-gutter-y: 0.5rem;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        --bs-gutter-x: 1rem;
    }
}

.provider-link {
    background: linear-gradient(to bottom, rgb(255, 78, 0), rgb(218, 65, 0));
    margin: auto;
    padding: 7px 20px;
    border-radius: 5px;
    text-align: center;
    width: fit-content;

    a {
        color: black;
        text-decoration: none;
    }
}

.bg-link-grey {
    background: #dbdbdb;
    text-decoration: none;

    a {
        color: black;
    }
}

// Login
.login-footer {
    padding-top: 30px;
    padding-bottom: 10px;

    span {
        display: flex;
        justify-content: center;
        margin-top: 15px;

        div {
            cursor: pointer;
            margin-left: 5px;
            color: $yellow;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.reset-password {
    cursor: pointer;
    width: fit-content;
    float: right;
    margin-bottom: 40px;

    &:hover {
        text-decoration: underline;
    }
}

.check-phone {
    position: absolute;
    top: 18px;
    right: 0;
}

.form-false {
    color: $red;
}

.form-true {
    color: $green;
}

#password-show {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
}

.login-bg {
    background-size: cover;
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100vh;
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.login-card {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding-bottom: 30px;
    width: 100%;
    padding: 48px 45px
}

.bar-icon {
    margin-left: 15px;
    font-size: 20px;
    color: #ff0500;
}

.offcanvas {
    @media (max-width: 576px) {
        width: 75% !important;
    }
}

.offcanvas-body {
    min-height: 100vh;
    background-color: #1e9fd9;
}

#announcement-modal {
    .modal-content {
        background-color: transparent;
        border: none;
    }
}

.subnav {
	background: linear-gradient(to bottom, rgb(81, 81, 81), rgb(21, 21, 21)) !important;
	// border-top: 1px solid rgb(211, 211, 211);
	margin-bottom: -1px;
}

.provide-name{
    color: white
}

.modal-content{
    background-color: rgb(62, 62, 62);
    // border: 1px solid rgb(216, 216, 216)
}

.modal-header .btn-close {
    filter: brightness(0) saturate(100%) invert(97%) sepia(40%) saturate(273%) hue-rotate(63deg) brightness(118%) contrast(100%);
}

.login-button{
    background-color: rgb(255, 78, 0);
    color: black;
    border: 1px solid  rgb(255, 78, 0);
}

.modal-login .modal-content{
    width: 100% !important;
    top: -40px;

    @media (max-width: 576px) {
        width: 85% !important;
    }
}

.modal.show .modal-dialog{
    justify-content: center;
}

.modal-language .modal-content{
    width: 90% !important;

    @media (max-width: 576px) {
        width: 70% !important;
    }
}