.promo-container {
    margin-bottom: 1.5rem;
}

.promo-card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    border-radius: 10px;
    background-color: #dcedfd;
}

.promo-image img {
    width: 100%;
    border-radius: 10px;
    aspect-ratio: 16 / 9;
}

.promo-title {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.promo-description {
    font-size: 14px;
}

.promo-image {
    position: relative;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .promo-title {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .promo-description {
        font-size: 11px;
    }

    .promo-content {
        margin-top: 10px;
    }
}

@media (min-width: 768px) {
    .promo-content {
        display: block;
        margin-top: 1rem;
    }

    .arrow-icon {
        display: none;
    }
}

@media (max-width: 767px) {
    .promo-content {
        display: none;
        padding: 10px;
        margin-top: 5px;
    }

    .promo-content.show-content {
        display: block;
    }

    .promo-card {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2px;
    }

    .promo-title {
        font-size: 1.1rem;
        margin-bottom: 5px;
    }

    .promo-description {
        font-size: 12px;
    }

    .arrow-icon {
        display: block;
        position: absolute;
        bottom: 45%;
        right: 20px;
        font-size: 14px;
        color: white;
    }
}