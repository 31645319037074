.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .loader-container {
    width: 100% !important;
  }
}

.loader-wrap {
  margin: auto;
  text-align: center;
}


.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  margin-bottom: 20px;
  position: relative;
  color: white;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

.spinner {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 3px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin-anim 1s linear infinite;
  display: inline-block;
  box-sizing: border-box;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}