$general: #d2d1cf;
$white: #fff;
$gray: #bbb;
$light_grey: #f5f5f7;
$black: #000;
$black_soft: #727c8c;
$black_col: #2e333c;
$black_more: #333333;
$dark: #1b2132;

$red: #ff5252;
$red_more: #e13f3f;
$color_more: #fc202c;

$yellow: #fc7811;
$yellow_more: #785500;
$orange: #e3941d;
$light_yellow: #fff5e0;

$green: #00cd13;
$green-primary: #288d9e;
$green-secondary: #00bf9a;
$green-soft: #bbd5d8;
$light_green: #9ccc65;
$light_soft_green: #caede7;

$blue: #dfecf7;
$light_blue: #c7e6f9;
$dark_blue: #051025;
$dark_blue_secondary: #0045e9;
$dark_blue_soft: #3290f0;
$purple: #6225E6;
$dark_purple: #15012C;
$light_purple: #353244;


$background: linear-gradient(#121732, #04060c);
$background_soft: #21242c;


$card-outline: #e8d69c;
$card-gradient: linear-gradient(to right, #f7e8c0, #fffbf2 35%);
$multiple-gradient: linear-gradient($white, $light_yellow, $white);
$gold-gradient: linear-gradient(to bottom, #f8d76c, #e3c260, #be9c4a, #8b662c 65%, #7c5624, #633610);

@media (prefers-color-scheme: dark) {
    :root {
        --header-bg: #353244;
    }
}